import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/Container';
import Image from '../components/Image';
import SignUpModal from '../components/SignUpModal';
import TrustedLogos from '../components/TrustedLogos';
import HowItWorksSlack from '../components/HowItWorksSlack';
import VerticalTabSection from '../components/VerticalTabSection';
import Testimonials from '../components/Testimonials';
import {
  BodyTextBig,
  CTASection,
  FlexWrap,
  GlobalHero,
  GraySection,
  HeroHeading,
  ImageWrap,
  SectionTitle,
  TextWrap,
  WhiteSection,
} from '../components/Global';
import theme from '../styles/theme';
import MediaBreakDown from '../styles/MediaBreakDown';


const SlackFeatures = styled.div`
  padding: 100px 0;
`;

const TestimonialSection = styled.div`
  padding: 100px 0 50px;
  text-align: center;
  background: ${theme.body.gray};
  ${MediaBreakDown.sm`
    padding: 60px 0 40px;
  `}
`;

const SlackPage = ({ location, data }) => {
  const createTaskData = [
    {
      title: 'Create tasks directly in Slack',
      subtitle: 'Create tasks using /todo in a channel',
      description: 'Workast is the only app you need to manage work in Slack. Easily create tasks in Slack when having a conversation by typing /todo. Create a task, assign the task and set a due date all at once.',
      image: 'stroke-1.png'
    },
    {
      title: 'Create tasks directly in Slack',
      subtitle: 'Convert a Slack message into a Workast task',
      description: 'Workast makes it simple for you to track action items. Easily use Workast to turn a Slack message into a Workast task that can be assigned to a team member to make sure work gets done.',
      image: 'Create-a-task-from-message.png'
    },
    {
      title: 'Create tasks directly in Slack',
      subtitle: 'Use the shortcuts menu to access Workast',
      description: 'Use Workast’s shortcut button in Slack to create and manage work from any channel or conversation.  Simply click on the shortcuts menu (⚡ icon) and choose your options.',
      image: 'Shortcuts-1.png'
    },
    {
      title: 'Create tasks directly in Slack',
      subtitle: 'Manage personal to-do’s easily',
      description: 'From any conversation, create quick memos for yourself using the /mytodo command. Tasks are organized in your personal space in Workast.',
      image: 'personal-todos.png'
    },
  ];
  const updateNotificationData = [
    {
      title: 'Updates and notifications directly in Slack',
      subtitle: 'Notifications conveniently through Slack',
      description: 'Workast sends all task updates and reminders through Slack so you can keep your email inbox free of clutter. No need to keep switching between email and Slack to see the progress of a project - notifications where you need them.',
      image: 'notifications.png'
    },
    {
      title: 'Receive custom reports in Slack',
      subtitle: 'Receive custom reports in Slack',
      description: 'Create a custom report in Workast and select how often you want to receive the report. Workast will deliver the list of tasks to your Slack account and a copy to anyone else that needs it.',
      image: 'reports-in-slack.png'
    },
    {
      title: 'Daily reminders for all your work',
      subtitle: 'Daily reminders for all your work',
      description: 'Receive a daily task list of the things you need to do for the day in Slack so you can keep on top of all your work. Customize the reminders to suit your needs!',
      image: 'daily-remainders.png'
    },
  ];
  return (
    <Layout location={location}>
      <SEO
        title="Workast for Slack |  Work & Task Management App  - Try it Today!"
        description="Workast is the only work management application you need in Slack. Capture tasks, manage projects, and track work with Workast in your Slack workspace."
        imageName="slack-hero.png"
      />
      <Container>
        <GlobalHero>
          <div className="hero-text">
            <HeroHeading>
              The missing piece of Slack
            </HeroHeading>
            <BodyTextBig mt="30px" mb="45px">
              With Workast you can set up projects,
              create tasks, capture to-dos,
              set due dates and assign work right from your conversation.
            </BodyTextBig>
            <div className="button-wrap">
              <SignUpModal buttonText="Get started for free" />
              <div className="label">No credit card required.</div>
            </div>
          </div>
          <div className="hero-img">
            <Image name="slack-hero.png" alt="hero screenshot" />
            <SignUpModal buttonText="Get started for free" />
          </div>
        </GlobalHero>
        <TrustedLogos />
      </Container>
      <GraySection>
        <SectionTitle>
          How it works
        </SectionTitle>
        <HowItWorksSlack />
      </GraySection>
      <SlackFeatures>
        <Container>
          <VerticalTabSection data={createTaskData} />
        </Container>
      </SlackFeatures>
      <GraySection>
        <Container>
          <div className="check-list">
            <SectionTitle>
              A checklist inside of Slack
            </SectionTitle>
            <BodyTextBig>
              See all your tasks for your team in Slack in an easy to use checklist.
              Easily complete a task, assign it to a team member and
              keep everyone up to date with what needs to be done.
            </BodyTextBig>
            <Image name="checklist.png" alt="checklist" />
          </div>
        </Container>
      </GraySection>
      <CTASection pMaxWidth="530px">
        <Container>
          <SectionTitle>
            Get started today
          </SectionTitle>
          <BodyTextBig>
            Workast is rated #1 in task management in the Slack marketplace.
            Join 100,000 other companies today.
          </BodyTextBig>
          <SignUpModal buttonText="Get started now" />
        </Container>
      </CTASection>
      <WhiteSection textAlign="left">
        <Container>
          <FlexWrap alignItems="center">
            <TextWrap width="40%">
              <SectionTitle mb="30px">
                Manage teamwork in Slack
              </SectionTitle>
              <BodyTextBig>
                Supercharge your Slack workspace with Workast
                so you can manage teamwork directly in Slack channels and
                keep your team organized.
              </BodyTextBig>
              <BodyTextBig>
                Easily view, complete, edit and manage tasks in a Slack channel by typing /
                todo list. This will show all the tasks in that Slack channel.
              </BodyTextBig>
              <BodyTextBig>
                Want more advanced features?
                Simply open Workast and manage all your teamwork in board or list view.
              </BodyTextBig>
            </TextWrap>
            <ImageWrap width="55%">
              <Image name="manage-team-work-in-slack.png" alt="manage-team-work-in-slack" />
            </ImageWrap>
          </FlexWrap>
        </Container>
      </WhiteSection>
      <GraySection>
        <Container>
          <VerticalTabSection data={updateNotificationData} />
        </Container>
      </GraySection>
      <CTASection>
        <Container>
          <SectionTitle>
            Get teamwork sorted
          </SectionTitle>
          <BodyTextBig>
            Start managing your teamwork today with Workast.
            Install Workast and get up and running in less than a minute.
          </BodyTextBig>
          <SignUpModal buttonText="Get started now" />
        </Container>
      </CTASection>
      <WhiteSection textAlign="left">
        <Container>
          <FlexWrap alignItems="center" direction="column-reverse">
            <ImageWrap width="55%">
              <Image name="forms-1.png" alt="form" />
            </ImageWrap>
            <TextWrap width="40%">
              <SectionTitle mb="30px">
                Complete custom forms in Slack
              </SectionTitle>
              <BodyTextBig>
                Streamline how you receive work by creating a customized form in Workast.
              </BodyTextBig>
              <BodyTextBig>
                Team members can complete the form from within Slack
                and a task is automatically created in Workast.
              </BodyTextBig>
            </TextWrap>
          </FlexWrap>
        </Container>
      </WhiteSection>
      <GraySection textAlign="left">
        <Container>
          <FlexWrap alignItems="center">
            <TextWrap width="45%">
              <SectionTitle mb="10px">
                A new step in the Slack Workflow Builder
              </SectionTitle>
              <BodyTextBig>
                Already creating workflows in Slack?
              </BodyTextBig>
              <BodyTextBig>
                With Workast you can “Create a Task” and connect this step to
                existing workflows to take your automation one step further.
              </BodyTextBig>
            </TextWrap>
            <ImageWrap width="45%">
              <Image name="workflow-builder-1.png" alt="workflow builder" />
            </ImageWrap>
          </FlexWrap>
        </Container>
      </GraySection>
      <WhiteSection textAlign="left">
        <Container>
          <FlexWrap alignItems="center" direction="column-reverse">
            <ImageWrap width="55%">
              <Image name="slack-enterprise.png" alt="slack enterprise support" />
            </ImageWrap>
            <TextWrap width="40%">
              <SectionTitle mb="10px">
                Slack Enterprise Support
              </SectionTitle>
              <BodyTextBig>
                Many large organizations with multiple Slack workspaces like IBM,
                American Express and Intuit have rolled out Workast to
                their employees, easily and securely.
              </BodyTextBig>
              <BodyTextBig>
                If you have a custom SSO we support it out of the box in our Essential plan.
              </BodyTextBig>
              <BodyTextBig>
                We automatically do user provisioning and deprovisioning keeping
                your Slack users in sync with Workast.
                Book a meeting to discuss enterprise features in more detail.
              </BodyTextBig>
            </TextWrap>
          </FlexWrap>
        </Container>
      </WhiteSection>
      <TestimonialSection>
        <SectionTitle mb="10px">
          Testimonials
        </SectionTitle>
        <Testimonials data={data.allContentfulTestimonial.edges} />
      </TestimonialSection>
      <CTASection>
        <Container>
          <SectionTitle>
            Get started now
          </SectionTitle>
          <BodyTextBig>
            Install Workast to your Slack workspace and start organizing your team work today.
          </BodyTextBig>
          <SignUpModal buttonText="Add to Slack" />
        </Container>
      </CTASection>
    </Layout>
  );
};

SlackPage.propTypes = {
  data: PropTypes.shape({
    allContentfulTestimonial: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            author: PropTypes.string.isRequired,
            role: PropTypes.string.isRequired,
            description: PropTypes.shape({
              description: PropTypes.string.isRequired
            }).isRequired,
            image: PropTypes.shape({
              fluid: PropTypes.shape({
                aspectRatio: PropTypes.number,
                base64: PropTypes.string,
                sizes: PropTypes.string,
                src: PropTypes.string,
                srcSet: PropTypes.string,
                srcSetWebp: PropTypes.string,
                srcWebp: PropTypes.string,
              }),
            }),
          }).isRequired,
        })
      ).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
};

export default SlackPage;

export const pageQuery = graphql`
  query SlackPageQuery {
    allContentfulTestimonial {
      edges {
        node {
          author
          role
          description {
            description
          }
          image {
            gatsbyImageData(width:150)
          }
        }
      }
    }
  }
`;

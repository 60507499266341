import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../styles/theme';
import MediaBreakDown from '../../styles/MediaBreakDown';
import constants from '../../constants';

const SliderWrap = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 40px;
  .slick-dots li button:before {
    font-size: 8px;
  }
  .slick-dots li.slick-active button:before {
    color: ${theme.background.blue};
  }

  /**
  This is to make the items all same size:
  */
  .slick-initialized {
    .slick-track {
      display: flex;
      align-items: stretch;

      .slick-slide {
        display: flex;
        height: initial;
        justify-content: center;

        & > div {
          display: flex;

          & > div {
            display: flex !important;
            flex: 1;
          }
        }
      }
    }
  }
`;

const SliderCard = styled.div`
  padding: 20px 40px;
  outline: none;
  

  ${MediaBreakDown.md`
    padding: 20px;
  `}
`;
SliderCard.displayName = 'SliderCard';
SliderCard.defaultProps = {
  size: constants.SIZES.MEDIUM,
};
SliderCard.propTypes = {
  size: PropTypes.string,
};

export {
  SliderCard,
};
export default SliderWrap;

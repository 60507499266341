import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { v4 } from 'uuid';

import theme from '../../styles/theme';
import Image from '../Image';
import { FlexWrap, SectionTitle, BodyText } from '../Global';
import MediaBreakDown from '../../styles/MediaBreakDown';

const fadeOut = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const Wrap = styled(FlexWrap)`
  align-items: center;

  .image-wrap {
    width: 55%;
  }
  .text-wrap {
    width: 40%;
    .item {
      font-size: 18px;
      line-height: 21.6px;
      font-weight: 700;
      color: ${theme.textColor.suvaGray};

      &:hover {
        cursor: pointer;
        color: ${theme.hover.hoverBlue};
      }
    }
    .mobile-image {
      display: none;
    }
    .active {
      color: ${theme.hover.hoverBlue};
    }
    .title {
      margin-left: 10px;
    }
    h2 {
      font-size: 2.75rem;
      line-height: 3.4rem;
      margin-bottom: 30px;
      text-align: left;
      color: ${theme.textColor.darkGrayText}
    }
    p {
      font-size: 18px;
      line-height: 21.6px;
    }
  }
  .show {
    display: block;
    animation-name: ${fadeOut};
    animation-duration: 0.5s;
    animation-iteration-count: lenear;
  }
  .hidden {
    display: none;
    opacity: 0;
  }
  ${MediaBreakDown.lg`
    .image-wrap {
      display: none;
      width: 100%;
    }
    .text-wrap {
      width: 100%;
      .mobile-image {
        display: block;
        margin-bottom: 20px;
      }
    }
  `}
  ${MediaBreakDown.md`
    .text-wrap {
      h2 {
        text-align: center;
        font-size: 28px;
        line-height: 34px;
      }
      .text-center {
        text-align: center !important;
        max-width: 500px;
        width: 100%;
        margin: 0 auto 20px;
      }
    }
  `}
  ${MediaBreakDown.sm`
    .text-wrap {
      .item {
        font-size: 17px;
      }
    }
  `}
`;

const VerticalTabSection = ({ data }) => {
  const [currentWork, setWork] = useState(data[0].subtitle);

  return (
    <Wrap>
      <div className="image-wrap">
        {
          data.map(item => (
            <div className={item.subtitle === currentWork ? 'show' : 'hidden'} key={v4()}>
              <Image name={item.image} alt={item.subtitle} />
            </div>
          ))
        }
      </div>
      <div className="text-wrap">
        { data[0].title ? (
          data.map(item => (
            <div key={v4()}>
              <h2 className={item.subtitle === currentWork ? 'show' : 'hidden'}>
                {item.title}
              </h2>
            </div>
          ))
        ) : (
          <>
            <SectionTitle mb="14px">
              Your work all in one place
            </SectionTitle>
            <BodyText className="text-center" mb="30px">
              Connect Workast with the apps you use every day and create
              unique workflows to simplify your most common tasks
            </BodyText>
          </>
        )}
        {
          data.map((item, i) => (
            <div key={v4()}>
              <h6
                className={item.subtitle === currentWork ? 'item active' : 'item'}
                onClick={() => setWork(item.subtitle)}
                aria-hidden="true"
              >
                #
                {i + 1}
                <span className="title">{item.subtitle}</span>
              </h6>
              <p className={item.subtitle === currentWork ? 'show' : 'hidden'}>
                {item.description}
              </p>
              <div className={item.subtitle === currentWork ? 'mobile-image show' : 'hidden'} key={v4()}>
                <Image name={item.image} alt={item.subtitle} />
              </div>
            </div>
          ))
        }
      </div>
    </Wrap>
  );
};
 
VerticalTabSection.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
    })
  ).isRequired
};

export default VerticalTabSection;

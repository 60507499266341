import React from 'react';
import styled from 'styled-components';

import Image from '../Image';
import {
  BodyTextBig,
  FlexWrap,
} from '../Global';

import MediaBreakDown from '../../styles/MediaBreakDown';

import LogoListBg from './logos-list-bg.svg';

const TrustedLogosWrap = styled.div`
  padding: 75px 0 95px;
  text-align: center;

  .logo-list-bg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .logo {
    .gatsby-image-wrapper {
      width: 100%;
    }
  }
  ${MediaBreakDown.sm`
    padding: 30px 0 90px;

    .logo {
      width: 35%;
      margin: 0 5%;
    }

    .logo-list-bg {
      display: none;
    }
  `}
`;

const TrustedLogos = () => (
  <TrustedLogosWrap>
    <BodyTextBig mb="32px">
      Trusted by over 100,000 teams
    </BodyTextBig>
    <div>
      <img className="logo-list-bg" src={LogoListBg} alt="logo list bg" />
      <FlexWrap maxWidth="650px" alignItems="center">
        <div className="logo">
          <Image name="american-express.png" alt="american express" />
        </div>
        <div className="logo">
          <Image name="expedia.png" alt="expedia" />
        </div>
        <div className="logo">
          <Image name="twitch_logo.png" alt="twitch" />
        </div>
        <div className="logo">
          <Image name="paypal.png" alt="paypal" />
        </div>
      </FlexWrap>
    </div>
  </TrustedLogosWrap>
);

export default TrustedLogos;

import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../styles/theme';
import MediaBreakDown from '../../styles/MediaBreakDown';
import constants from '../../constants';
import { SliderCard } from '../Slider/index.style';


const SliderCardForTestimonial = styled(SliderCard)`
  padding: 20px 40px;
  outline: none;
  
  .card-content {
    display: flex;
    align-items: start;
    background: ${theme.background.light};
    border: 1px solid ${theme.borderColor.shadeGray};
    border-radius: 6px;
    padding: 20px;
    min-height: ${props => (props.size === constants.SIZES.MEDIUM
    ? '180px'
    : 'initial')};
    
    .image-wrap {
      max-width: ${props => (props.size === constants.SIZES.MEDIUM
    ? '150px'
    : '80px')};
      min-width: ${props => (props.size === constants.SIZES.MEDIUM
    ? '150px'
    : '80px')};
      margin-right: 15px;

      img {
        border-radius: 6px;
      }
    }
    .text-wrap {
      text-align: left;
      font-size: ${props => (props.size === constants.SIZES.MEDIUM
    ? '18px'
    : '14px')};
      line-height: 21.6px;

      .author {
        margin-top: 10px;
        color: ${theme.textColor.blackText};

        strong {
          margin-right: 10px;
        }
      }
    }
  }

  ${MediaBreakDown.md`
    padding: 20px;

    .card-content {
      flex-direction: column;
      align-items: center;
      
      .image-wrap {
        max-width: 150px;
        margin-bottom: 10px;
      }

      .text-wrap {
      }
    }
  `}
`;
SliderCardForTestimonial.displayName = 'SliderCardForTestimonial';
SliderCardForTestimonial.defaultProps = {
  size: constants.SIZES.MEDIUM,
};
SliderCardForTestimonial.propTypes = {
  size: PropTypes.string,
};

export {
  SliderCardForTestimonial,
};
export default null;

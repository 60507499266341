import React, {
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { v4 } from 'uuid';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  SliderCardForTestimonial,
} from './index.style';
import SliderWrap from '../Slider/index.style';
import constants from '../../constants';

const Testimonials = ({
  data,
  size,
  sliderSettings,
}) => {
  const defaultSettings = {
    infinite: true,
    autoPlay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    className: 'center',
    centerMode: true,
    dots: true,
    centerPadding: '20%',
    responsive: [{
      breakpoint: 990,
      settings: {
        centerPadding: '10%',
      }
    }, {
      breakpoint: 767,
      settings: {
        centerPadding: '10px',
      }
    }]
  };
  const settings = {
    ...defaultSettings,
    ...sliderSettings,
  };

  const refSlider = useRef();

  useEffect(() => {
    // This is required to make it work. Or it won't start right away. It's a bug:
    setTimeout(() => {
      refSlider.current.slickPlay();
    }, 500);
  }, []);

  return (
    <SliderWrap>
      <Slider
        {...settings}
        ref={refSlider}
      >
        {
          data.map(item => (
            <SliderCardForTestimonial
              key={v4()}
              size={size}
            >
              <div className="card-content">
                <div className="image-wrap">
                  <GatsbyImage alt={item.node.author} image={getImage(item.node.image)} />
                </div>
                <div className="text-wrap">
                  &quot;
                  {item.node.description.description}
                  &quot;
                  <p className="author">
                    <strong>{item.node.author}</strong>
                    {item.node.role}
                  </p>
                </div>
              </div>
            </SliderCardForTestimonial>
          ))
        }
      </Slider>
    </SliderWrap>
  );
};

Testimonials.defaultProps = {
  size: constants.SIZES.MEDIUM,
  sliderSettings: {},
};
Testimonials.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        author: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        description: PropTypes.shape({
          description: PropTypes.string.isRequired
        }).isRequired,
        image: PropTypes.shape({
          fluid: PropTypes.shape({
            aspectRatio: PropTypes.number,
            base64: PropTypes.string,
            sizes: PropTypes.string,
            src: PropTypes.string,
            srcSet: PropTypes.string,
            srcSetWebp: PropTypes.string,
            srcWebp: PropTypes.string,
          }),
        }),
      }).isRequired,
    })
  ).isRequired,
  size: PropTypes.oneOf([
    constants.SIZES.SMALL,
    constants.SIZES.MEDIUM,
  ]),
  sliderSettings: PropTypes.shape({}),
};

export default Testimonials;
